import tw from "tailwind-styled-components";

export const BannerWrapper = tw.div`
  flex
  flex-col
  gap-8
  bg-secondary-100
  p-10
  mx-[-16px]

  desktop:items-center
  desktop:mx-0
  desktop:rounded-3xl
`;

export const BannerText = tw.h3`
  text-center
  text-3xl
`;

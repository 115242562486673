import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { Section } from "../index";
import { BannerWrapper, BannerText } from "./styles";

const Link = dynamic(() => import("../../elements/link/link"));

export const BannerMoreInformation = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  text,
  button,
  displaySetting
}) => {
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <BannerWrapper>
        {text && <BannerText>{renderLineBreaks(text)}</BannerText>}
        {button && <Link type="buttonPrimary" {...button} />}
      </BannerWrapper>
    </Section>
  );
};

export default BannerMoreInformation;
